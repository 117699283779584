import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Stack,
  Button,
  Container,
  Chip,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDialog } from 'components/Dialog'
import Iconify from 'components/Iconify';
import List from 'components/List';
import Breadcrumbs from 'components/Breadcrumbs'
import InviteForm from 'sections/dialogs/InviteForm';

import { getInvites, deleteInvite } from 'services/requests/invite';
import { setMask, toast } from 'utils';

export default function InvitesInvited() {
  const { t } = useTranslation('common');
  const dialog = useDialog();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [isInviting, setIsInviting] = useState(false);
  const [selectedInvite, setSelectedInvite] = useState(null);

  const handleSelectInvite = (row, e) => {
    if (e.target.tagName === 'A') return null
    e.preventDefault()
    setSelectedInvite(row)
  }

  const handleCloseModal = () => {
    setIsInviting(false)
    setSelectedInvite(null)
  }

  const copyInvite = (e, invite) => {
    e.stopPropagation()

    const message = `Olá ${invite.name}, você foi convidado(a) para fazer parte da ${process.env.REACT_APP_PROJECT_NAME}!\n\nAcesse o link abaixo para criar sua conta:\n${window.location.origin}/auth/convite/${invite.token}`
    navigator.clipboard.writeText(message)
    toast('Convite copiado', 'success')
  }

  const deleteItem = (e, invite) => {
    e.stopPropagation()

    dialog({
      title: 'Remover convite',
      description: `Deseja realmente remover o convite de ${invite.name}?`
    })
      .then(() => {
        confirmDelete(invite.token)
      })
      .catch(() => { })
  }

  const confirmDelete = token => {
    deleteInvite({ token })
      .then(() => {
        toast('Convite removido com sucesso!', 'success')
        getData()
      })
      .catch((err) => toast(err, 'error'))
  }

  const listConfig = {
    placeholderFilter: t('invitesInvited.search'),
    onClick: (row, e) => handleSelectInvite(row, e),
    disabledRow: row => Boolean(row.userId),
    fields: [
      { key: 'name', label: t('invitesInvited.listFields.name'), desktopColumnSize: 3, isPrimary: true },
      { key: 'email', label: t('invitesInvited.listFields.email'), desktopColumnSize: 3 },
      {
        key: 'phone',
        label: t('invitesInvited.listFields.phone'),
        desktopColumnSize: 3,
        customValue: row => (
          <>
            <a href={`https://wa.me/+55${row.phone}`} target="_blank">{setMask('(99) 99999-9999', row.phone)}</a>
            <Iconify icon="mingcute:whatsapp-fill" width={15} sx={{ ml: 1 }} />
          </>
        )
      },
      {
        key: 'typeLabel', label: t('invitesProvider.listFields.typeStatus'), desktopColumnSize: 3, customValue: row => (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row">
              <Chip label={row.typeLabel} size="small" sx={{ mr: 1 }} color={row.type === 'seller' ? 'primary' : 'secondary'} />
            </Stack>
            {
              row?.token && !row.userId ? (
                <Stack direction="row" justifyContent="flex-end">
                  <IconButton sx={{ ml: 1 }} onClick={e => copyInvite(e, row)}>
                    <Iconify icon="mdi:share" />
                  </IconButton>
                  <IconButton sx={{ color: 'error.main', ml: 1 }} onClick={e => deleteItem(e, row)}>
                    <Iconify icon="eva:trash-2-fill" />
                  </IconButton>
                </Stack>
              ) : null
            }
          </Stack>
        )
      }
    ]
  }

  const getData = () => {
    setLoadingData(true)

    getInvites()
      .then((res) => {
        const parsed = res.map(({ type, name, email, phone, token, userId }) => ({
          type, name, email, phone, token, userId,
          typeLabel: t(`invitesProvider.types.${type}`),
        }))

        setData(parsed)
      })
      .catch((err) => toast(err, 'error'))
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title> {t('invitesInvited.pageTitle')} | { process.env.REACT_APP_PROJECT_NAME } </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs
            items={[
              { label: t('invitesInvited.title') }
            ]}
          />

          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setIsInviting(true)}
          >
            {t('invitesInvited.new.invite')}
          </Button>
        </Stack>

        <List
          config={listConfig}
          data={data}
          refresh={getData}
          loading={loadingData}
        />
      </Container>

      {
        (isInviting || selectedInvite?.token) && (
          <InviteForm
            open={selectedInvite?.token ? true : isInviting}
            defaultValues={selectedInvite || null}
            onCancel={() => handleCloseModal()}
            onSuccess={() => getData()}
          />
        )
      }
    </>
  )
}
