import PropTypes from 'prop-types'
import { useRef } from 'react'
import {
  Stack,
  Typography,
  Button,
  FormHelperText,
  IconButton
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useTranslation } from 'react-i18next';

import Iconify from 'components/Iconify'
import PdfPreviewImage from 'components/ImageGallery/pdf-image'

export default function AcceptInviteFormStepPersonalDocument({ values, touched, errors, setFieldValue }) {
  const { t } = useTranslation('common');
  const inputFileRef = useRef(null);

  const handleRemoveFile = index => {
    const arr = [...values.document]
    arr.splice(index, 1)
    if (arr.length > 0) {
      setFieldValue('document', [...arr])
    } else {
      setFieldValue('document', null)
    }
  }

  const handleSelectFile = file => {
    inputFileRef.current.value = null
    if (values.document?.length > 0) {
      setFieldValue('document', [...values.document, Object.assign(file, { preview: URL.createObjectURL(file) })])
    } else {
      setFieldValue('document', [Object.assign(file, { preview: URL.createObjectURL(file) })])
    }
  }

  const onBtnClick = () => {
    inputFileRef.current.click();
  }

  return (
    <Stack spacing={3} mb={6}>
      <Typography variant="subtitle1">
        Adicione as imagens de frente e verso do seu documento. <nobr>(Exemplo: CNH, RG...)</nobr>
      </Typography>

      {
        values.document?.length > 0 ? (
          <Stack alignItems="center">
            {
              values.document?.map((item, key) => (
                item?.preview ? (
                  <Stack sx={{ position: 'relative', width: 150, height: 150, mt: key > 0 ? 5 : 0 }} key={key}>
                    {
                      item.type.match('/pdf') ? (
                        <PdfPreviewImage
                          file={item.preview}
                          firstPageOnly
                          imageStyle={{ width: 150, height: 150 }}
                        />
                      ) : (
                        <img
                          src={item.preview}
                          width="150" height="150"
                          style={{ objectFit: 'contain', borderRadius: '4px' }}
                          alt="Document"
                        />
                      )
                    }
                    <IconButton onClick={() => handleRemoveFile(key)} sx={{ position: 'absolute', right: -20, top: -15 }} variant="contained" color="error">
                      <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                  </Stack>
                ) : null
              ))
            }
          </Stack>
        ) : null
      }

      <input
        ref={inputFileRef}
        accept="image/png, image/jpeg, image/gif, image/webp, application/pdf"
        id="document"
        type="file"
        hidden
        onChange={e => handleSelectFile(e.target.files[0])}
      />
      {
        !(values.document?.length >= 5) ? (
          <label htmlFor="document">
            <Button
              variant="contained"
              size="large"
              endIcon={<AddPhotoAlternateIcon />}
              onClick={() => onBtnClick()}
              fullWidth
            >
              {t('auth.acceptInvite.form.document')}
            </Button>
          </label>
        ) : null
      }
      {
        touched.document && Boolean(errors.document) ? (
          <FormHelperText
            error={touched.document && Boolean(errors.document)}
          >
            {touched.document && errors.document}
          </FormHelperText>
        ) : null
      }
    </Stack>
  );
}

AcceptInviteFormStepPersonalDocument.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func
}
