import PropTypes from 'prop-types'
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextField from 'components/TextField';

export default function FormStepObservation({ values, handleChange }) {
  const { t } = useTranslation('common');

  return (
    <Stack spacing={3}>
      <TextField
        multiline
        name="observation"
        label={t('myProfile.observation')}
        value={values.observation}
        onChange={handleChange}
      />
    </Stack>
  );
}

FormStepObservation.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func
}
