import { GlobalStyles as MUIGlobalStyles } from '@mui/material'
import mix from 'mix-css-color'

export default function GlobalStyles() {
  return (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        a: {
          color: 'inherit',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '#mui-rte-container [class*="MUIRichTextEditor-placeHolder"]': {
          position: 'relative !important'
        },
        '.vision-reorder-card': {
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#139BC3',
          color: '#FFFFFF',
          borderRadius: 16,
          padding: 16
        },
        '.vision-reorder-card svg': {
          marginBottom: 8
        },
        '.link': {
          cursor: 'pointer'
        },
        '.MuiStepIcon-root.Mui-active': {
          color: `#${process.env.REACT_APP_PROJECT_COLOR} !important`
        },
        '.rmdp-wrapper.rmdp-shadow': {
          width: '100%',
          boxShadow: '0 0 2px #8798ad'
        },
        '.rmdp-calendar': {
          width: '100%'
        },
        '.rmdp-day-picker > div': {
          width: '100%'
        },
        '.purple .rmdp-day:not(.rmdp-day-hidden) span:hover': {
          backgroundColor: `transparent !important`,
          color: 'inherit !important'
        },
        '.purple .rmdp-day.rmdp-selected span:hover': {
          backgroundColor: `var(--rmdp-primary-purple) !important`,
          color: '#FFF !important'
        },
        '.logo-openpix-comprovante': {
          width: '150px',
          maxWidth: '70%',
          pointerEvents: 'none',
          marginBottom: 12
        },
        '.terms-link a': {
          color: `#${process.env.REACT_APP_PROJECT_COLOR} !important`,
        },
        '.ellipsis': {
          display: '-webkit-box',
          WebkitLineClamp: '5',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '.pinch-zoom-pan': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
        '.pinch-zoom-pan img': {
          maxHeight: '80vh',
          maxWidth: '90vw'
        },
        '.password-checklist': {
          textAlign: 'left',
          fontSize: 14,
          marginTop: '8px !important'
        }
      }}
    />
  )
}
