import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

const Field = ({ label, value, link }) => (
  <Box mb={2}>
    <Typography color="text.primary" variant="subtitle1">
      {label}
    </Typography>
    {
      link ? (
        <Typography color="text.secondary" variant="body1" component={Link} to={link}>
            {value}
        </Typography>
      ) : (
        <Typography color="text.secondary" variant="body1">
          {value}
        </Typography>
      )
    }
  </Box>
);

Field.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  link: PropTypes.string
}

export default Field
