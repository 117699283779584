import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Box,
  Card,
  Paper,
  Divider,
  Typography,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  TablePagination,
  CircularProgress,
  InputAdornment,
  Button,
  IconButton
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';

import { useTranslation } from 'react-i18next'

import Iconify from 'components/Iconify'

import { StyledRoot, StyledSearch } from './styled'

_List.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  refresh: PropTypes.func,
  loading: PropTypes.bool,
}

export default function _List({ config, data, refresh, loading, onDownloadList, loadingDownloadList, showInput=true, showAvatar=true, showPagination=true }) {
  const { t } = useTranslation('common')
  const rowsPerPage = showPagination ? 20 : data?.length

  const [page, setPage] = useState(0)
  const [filter, setFilter] = useState('')

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleFilter = (event) => {
    setPage(0)
    setFilter(event.target.value)
  }

  const filteredItems = data.filter(res => (JSON.stringify(res).toLocaleLowerCase()).includes(`${filter.toLocaleLowerCase()}`))

  const isNotFound = !filteredItems.length

  return (
    <>
      <Card>
        {
          loading ? (
            <Paper
              sx={{
                textAlign: 'center',
              }}
            >
              <CircularProgress size={80} sx={{ my: 12 }} />
            </Paper>
          ) : (
            <>
              {showInput ?
              (<StyledRoot>
                <StyledSearch
                  value={filter}
                  onChange={handleFilter}
                  placeholder={config.placeholderFilter}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                  />

                  {
                    typeof refresh === 'function' ? (
                      <IconButton onClick={() => refresh()}>
                        <Iconify icon="material-symbols:refresh" sx={{ color: 'text.disabled', width: 30, height: 30 }} />
                      </IconButton>
                    ) : null
                  }
                </StyledRoot>) : null
              }

              <Divider />

              <List dense={false} sx={{ pt: 0 }}>
                <ListItem
                  divider
                  sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex' },
                    background: 'rgba(253, 253, 253, 1)'
                  }}
                >
                  {
                    showAvatar ? (
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'transparent' }}>
                          <Iconify icon="eva:list-fill" />
                        </Avatar>
                      </ListItemAvatar>
                    ) : null
                  }
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {
                      config.fields.map((_item, _key) => (
                        <Grid item xs={_item.desktopColumnSize} zeroMinWidth key={`header-${_key}`}>
                          <Typography variant="subtitle2" noWrap>
                            {_item.label}
                          </Typography>
                        </Grid>
                      ))
                    }
                  </Grid>
                </ListItem>

                {
                  data?.length ? (
                    <>
                      {
                        filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, key) => (
                          <ListItemButton
                            divider
                            key={key}
                            sx={{ py: 2 }}
                            onClick={(e) => config.onClick(item, e)}
                            disabled={config?.disabledRow?.(item) || false}
                          >
                            {showAvatar ? <ListItemAvatar>
                              <Avatar alt={item.name}>
                                {item.name?.slice(0, 1)}
                              </Avatar>
                            </ListItemAvatar> : null}


                            <Grid
                              container
                              columnSpacing={{ sm: 2, md: 3 }}
                              sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
                              >
                              {
                                config.fields.map((_item, _key) => (
                                  <Grid item xs={_item.desktopColumnSize} zeroMinWidth key={`${key}-${_key}`}>
                                    <Typography variant="body1" noWrap>
                                      {_item?.customValue?.(item) || item[_item.key]}
                                    </Typography>
                                  </Grid>
                                ))
                              }
                            </Grid>

                            <ListItemText
                              primary={item?.[config.fields.find(_item => _item?.isPrimary)?.key]}
                              sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
                              secondary={(
                                <>
                                  {
                                    config.fields
                                      .filter(_item => !_item.isPrimary)
                                      .map((_item, _key) => (
                                        <Typography
                                          key={`item-${key}-${_key}`}
                                          sx={{ display: 'inline' }}
                                          variant="body2"
                                          component="span"
                                        >
                                          {_item?.sm_prefix}{_item?.customValue?.(item) || item[_item.key]}
                                        </Typography>
                                      ))
                                      .reduce((acc, x) => acc === null ? [x] : [acc, <><br /></>, x], null)
                                  }
                                </>
                              )}
                            />

                            {/* <ListItemIcon>
                              <Iconify icon="eva:arrow-ios-forward-fill" />
                            </ListItemIcon> */}
                          </ListItemButton>
                        ))
                      }

                      {
                        isNotFound ? (
                          <Paper
                            sx={{
                              textAlign: 'center',
                              my: 6
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              {t('searchNotFound')}
                            </Typography>

                            <Typography variant="body2">
                              {t('searchNotFoundResults')} &nbsp;
                              <strong>&quot;{filter}&quot;</strong>.
                              <br /> {t('searchNotFoundResults_2')}
                            </Typography>
                          </Paper>
                        ) : null
                      }
                    </>
                  ) : (
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Box
                        component="img"
                        src="/assets/illustrations/illustration_empty_content.svg"
                        sx={{ height: 160, mx: 'auto', my: 5 }}
                      />
                      <Typography variant="h6" paragraph>
                        {t('no_data')}
                      </Typography>
                    </Paper>
                  )
                }
              </List>

              {
                onDownloadList || showPagination ? (
                  <Grid container>
                    {
                      onDownloadList ? (
                        <Grid item xs={showPagination ? 3 : 12} sx={{ px: 2, pb: 2, pt: 1 }}>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => onDownloadList()}
                              endIcon={<DownloadIcon />}
                              loading={loadingDownloadList}
                              m={2}
                            >
                              Download
                            </Button>
                        </Grid>
                      ) : null
                    }
                    {
                      showPagination ? (
                        <Grid item xs={onDownloadList ? 9 : 12}>
                          <TablePagination
                            component="div"
                            count={filteredItems.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[]}
                            labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count !== -1 ? count : `${t('moreThan')} ${to}`}`}
                          />
                        </Grid>
                      ) : null
                    }
                  </Grid>
                ) : null
              }
            </>
          )
        }
      </Card>
    </>
  )
}
