import React from 'react';
import PropTypes from 'prop-types';

import Iconify from 'components/Iconify'

import './styles.css';

const containerStyle = {
  position: 'absolute',
  top: 10,
  right: 170,
  zIndex: 1000
};

const ZoomOutButton = ({ disabled, onClick }) => (
  <button className='iconButton' style={{ margin: '10px', marginRight: '5px' }} onClick={onClick} disabled={disabled}>
    <Iconify icon="ic:baseline-minus" width={15} />
  </button>
);

const ZoomInButton = ({ disabled, onClick }) => (
  <button className='iconButton' style={{ margin: '10px', marginLeft: '0px' }} onClick={onClick} disabled={disabled}>
    <Iconify icon="ic:baseline-plus" width={15} />
  </button>
);

const ZoomButtons = ({ scale, minScale, maxScale, onZoomInClick, onZoomOutClick }) => (
  <div style={containerStyle}>
    <ZoomOutButton onClick={onZoomOutClick} disabled={scale <= minScale} />
    <ZoomInButton onClick={onZoomInClick} disabled={scale >= maxScale} />
  </div>
);

ZoomButtons.propTypes = {
  scale: PropTypes.number.isRequired,
  minScale: PropTypes.number.isRequired,
  maxScale: PropTypes.number.isRequired,
  onZoomInClick: PropTypes.func.isRequired,
  onZoomOutClick: PropTypes.func.isRequired,
};

export default ZoomButtons;
