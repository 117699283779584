import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Box,
  Modal,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography
} from '@mui/material'
import { Info } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { useDialog } from 'components/Dialog'
import Iconify from 'components/Iconify'
import PinchZoomPan from 'components/PinchZoomPan'

import PdfPreviewImage from './pdf-image'
import { toast } from 'utils'

const ImageGallery = ({ files, folder, onRemoveFile }) => {
  const { t } = useTranslation('common')
  const dialog = useDialog()

  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpenDocument = file => {
    setSelectedImage(file)
  }

  const handleCloseDocument = () => {
    setSelectedImage(null)
  }

  const handleDownload = async (url, name) => {
    try {
      const response = await fetch(url + name)
      if (!response.ok) {
        throw new Error('Falha ao baixar o arquivo')
      }
      const blob = await response.blob()
      const contentType = response.headers.get("content-type")
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      if (contentType && contentType.includes("pdf")) {
        window.open(link.href, '_blank')
      } else {
        link.download = name
        link.click()
      }
      URL.revokeObjectURL(link.href)
    } catch (error) {
      toast('Erro ao baixar o arquivo:', 'error')
    }
  }

  const handleRemoveFile = () => {
    dialog({
      title: 'Remover documento',
      description: `Deseja realmente remover o documento selecionado?`
    })
      .then(() => {
        onRemoveFile(selectedImage.key)
        handleCloseDocument()
      })
      .catch(() => { })
  }

  return (
    <>
      <ImageList cols={3}>
        {
          files.map((item, key) => (
            <ImageListItem
              key={key}
              onClick={() => handleOpenDocument(item)}
              className="link"
            >
              {
                item.key.match('.pdf') ? (
                  <PdfPreviewImage
                    file={`${process.env.REACT_APP_S3_URL}/${folder}/${item.key}`}
                    imageStyle={{ height: 200 }}
                    firstPageOnly
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_S3_URL}/${folder}/${item.key}`}
                    alt={t(`enum.fileType.${item.type}`)}
                    style={{ height: 200 }}
                    loading="lazy"
                  />
                )
              }
              <ImageListItemBar
                title={t(`enum.fileType.${item.type}`)}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label="info"
                  >
                    <Info />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))
        }
      </ImageList>

      {
        selectedImage ? (
          <Modal
            open={Boolean(selectedImage?.key)}
            onClose={handleCloseDocument}
          >
            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100vh' }}>
              <Box className="pinch-zoom-pan">
                {
                  selectedImage.key.match('.pdf') ? (
                    <PdfPreviewImage
                      file={`${process.env.REACT_APP_S3_URL}/${folder}/${selectedImage.key}`}
                      withZoom
                    />
                  ) : (
                    <PinchZoomPan maxScale={3}>
                      <img
                        src={`${process.env.REACT_APP_S3_URL}/${folder}/${selectedImage.key}`}
                        alt={t(`enum.fileType.${selectedImage.type}`)}
                        loading="lazy"
                      />
                    </PinchZoomPan>
                  )
                }
              </Box>

              <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                <IconButton
                  sx={{ color: '#FFF', padding: '24px 15px' }}
                  aria-label="download"
                  onClick={() => handleDownload(`${process.env.REACT_APP_S3_URL}/${folder}/`, selectedImage.key)}
                >
                  <Iconify icon="zondicons:download" width={25} />
                </IconButton>

                <IconButton
                  sx={{ color: '#FFF', padding: '24px 15px' }}
                  onClick={() => handleRemoveFile()}
                  aria-label="delete"
                >
                  <Iconify icon="zondicons:trash" width={25} />
                </IconButton>

                <IconButton
                  sx={{ color: '#FFF', padding: '24px 15px' }}
                  onClick={() => handleCloseDocument()}
                  aria-label="close"
                >
                  <Iconify icon="zondicons:close-solid" width={25} />
                </IconButton>
              </Box>

              <Typography
                sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%', color: '#FFF', padding: 3 }}
                textAlign="center"
              >
                {t(`enum.fileType.${selectedImage.type}`)}
              </Typography>
            </Box>
          </Modal>
        ) : null
      }
    </>
  )
}

ImageGallery.propTypes = {
  files: PropTypes.array,
  onRemoveFile: PropTypes.func
}

export default ImageGallery
