import React, { useState, useEffect } from 'react'
import { pdfjs } from 'react-pdf'

import PinchZoomPan from 'components/PinchZoomPan'
import Iconify from 'components/Iconify'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const buttonStyle = {
  position: 'absolute',
  top: 10,
  right: 260,
  zIndex: 1000
};

const PrevButton = ({ disabled, onClick }) => (
  <button className='iconButton' style={{ margin: '10px', marginRight: '5px' }} onClick={onClick} disabled={disabled}>
    <Iconify icon="ic:baseline-navigate-before" width={15} />
  </button>
);

const NextButton = ({ disabled, onClick }) => (
  <button className='iconButton' style={{ margin: '10px', marginLeft: '0px' }} onClick={onClick} disabled={disabled}>
    <Iconify icon="ic:baseline-navigate-next" width={15} />
  </button>
);

const NavigationButtons = ({ totalPages, currentPage, nextPageClick, backPageClick }) => (
  <div style={buttonStyle}>
    <PrevButton onClick={backPageClick} disabled={currentPage <= 1} />
    <NextButton onClick={nextPageClick} disabled={currentPage >= totalPages} />
  </div>
);

const PdfPreviewImage = ({ file, imageStyle = {}, firstPageOnly, withZoom }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState([])

  const renderPage = async (pdf, numberPage) => {
    const page = await pdf.getPage(numberPage)

    const scale = 1.5
    const viewport = page.getViewport({ scale })

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width

    await page.render({
      canvasContext: context,
      viewport: viewport,
    }).promise

    const image = canvas.toDataURL()
    setImageUrl(images => ({
      ...images,
      [numberPage]: image
    }))
  }

  const loadDocument = async (pdfFile) => {
    const pdf = await pdfjs.getDocument(pdfFile).promise

    const numPages = firstPageOnly ? 1 : pdf._pdfInfo.numPages
    setTotalPages(numPages)
    for (let i = 1; i <= numPages; i++) {
      await renderPage(pdf, i)
    }
  }

  useEffect(() => {
    loadDocument(file)
  }, [file])

  return (
    imageUrl ? (
      withZoom ? (
        <>
          <PinchZoomPan maxScale={3}>
            <img
              src={imageUrl[currentPage]}
              alt="Document"
              style={{ ...imageStyle, objectFit: 'contain' }}
              className="MuiImageListItem-img"
              loading="lazy"
            />
          </PinchZoomPan>
          {
            !firstPageOnly ? (
              <NavigationButtons
                totalPages={totalPages}
                currentPage={currentPage}
                backPageClick={() => setCurrentPage(curr => curr - 1)}
                nextPageClick={() => setCurrentPage(curr => curr + 1)}
              />
            ) : null
          }
        </>
      ) : (
        <img
          src={imageUrl[currentPage]}
          alt="Document"
          style={{ ...imageStyle, objectFit: 'contain' }}
          className="MuiImageListItem-img"
          loading="lazy"
        />
      )
    ) : null
  )
}

export default PdfPreviewImage
