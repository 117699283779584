import PropTypes from 'prop-types'
import { useState, useRef } from 'react'
import { Stack } from '@mui/material';
import { Formik, Form } from 'formik';

import Dialog from 'components/Dialog';
import {
  AcceptInviteFormStepPersonal,
  AcceptInviteFormStepPersonalAddress,
  AcceptInviteFormStepCompany,
  AcceptInviteFormStepCompanyAddress,
} from 'sections/auth';
import FormStepObservation from 'sections/user/Observation';

import { updateMe, updateUser } from 'services/requests/user';
import { removeMask, toast } from 'utils';

const steps = ['dados do responsável', 'endereço do responsával', 'dados da empresa', 'endereço comercial', 'observação'];

function _renderStepContent({ formIndex, ...props }) {
  switch (formIndex) {
    case 0:
      return (
        <AcceptInviteFormStepPersonal {...props} />
      );
    case 1:
      return (
        <AcceptInviteFormStepPersonalAddress {...props} />
      );
    case 2:
      return (
        <AcceptInviteFormStepCompany {...props} />
      );
    case 3:
      return (
        <AcceptInviteFormStepCompanyAddress {...props} />
      );
    case 4:
      return (
        <FormStepObservation {...props} />
      );
    default:
      return <div>Not Found</div>
  }
}

export default function EditProfile({ id = undefined, formIndex, defaultValues = {}, onCancel, onSuccess }) {
  // eslint-disable-next-line global-require
  const validationSchema = require('../../pages/MyProfile/FormModel/validationSchema')

  const formRef = useRef()

  const [isLoading, setIsLoading] = useState(false)

  function handleSubmitValues(values, actions) {
    setIsLoading(true)
    actions.setTouched({})

    const body = {}

    if (formIndex === 0) {
      const {
        name,
        email,
        phone,
        cpf,
        rg,
        birthDate,
        profession,
        nationality,
        maritalStatus,
        propertyRegime
      } = values

      body.user = {
        name,
        email,
        phone: removeMask(phone),
        cpf: removeMask(cpf),
        rg,
        birthDate: birthDate.split('/').reverse().join('-'),
        profession,
        nationality,
        maritalStatus,
        propertyRegime
      }
    } else if (formIndex === 1) {
      const {
        zipcode,
        street,
        neighborhood,
        city,
        state,
        number,
        complement
      } = values

      body.userAddress = {
        zipcode,
          street,
          neighborhood,
          city,
          state,
          number,
          complement
      }
    } else if (formIndex === 2) {
      const {
        socialName,
        fantasyName,
        emailCompany,
        phoneCompany,
        cnpj,
        pixType,
        pix
      } = values;

      body.company= {
        name: socialName,
        fantasyName,
        email: emailCompany,
        phone: removeMask(phoneCompany),
        cnpj: removeMask(cnpj),
        pixType,
        pix: (pixType === 'phone' || pixType === 'cnpj' || pixType === 'cpf') ? removeMask(pix) : pix
      }
    } else if (formIndex === 3) {
      const {
        zipcodeCompany,
        streetCompany,
        neighborhoodCompany,
        cityCompany,
        stateCompany,
        numberCompany,
        complementCompany,
      } = values;

      body.companyAddress = {
        zipcode: zipcodeCompany,
        street: streetCompany,
        neighborhood: neighborhoodCompany,
        city: cityCompany,
        state: stateCompany,
        number: numberCompany,
        complement: complementCompany
      }
    } else if (formIndex === 4) {
      const {
        observation
      } = values;

      body.user = {
        observation
      }
    }

    const request = id ? updateUser : updateMe

    request({ id, body })
      .then(() => {
        toast('Perfil atualizado com sucesso!', 'success')
        onSuccess()
      })
      .catch((err) => toast(err, 'error'))
      .finally(() => {
        actions.setSubmitting(false)
        setIsLoading(false)
      });
  }

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  return (
    formIndex >= 0 &&
    <Dialog
      open={formIndex >= 0}
      options={{
        title: `Editar ${steps[formIndex]}`,
        content: (
          <Stack py={3}>
            <Formik
              innerRef={formRef}
              initialValues={{ ...defaultValues }}
              validationSchema={validationSchema.default[formIndex]}
              onSubmit={(values, actions) => handleSubmitValues(values, actions)}
            >
              {({ values, handleChange, touched, errors, setFieldValue }) => (
                <Form id="editProfile">
                  {
                    _renderStepContent({
                      formIndex, values, handleChange, touched, errors, setFieldValue, isEditing: true
                    })
                  }
                </Form>
              )}
            </Formik>
          </Stack>
        ),
        cancellationText: 'Cancelar',
        confirmationText: 'Salvar',
        confirmationButtonProps: {
          loading: isLoading
        }
      }}
      onCancel={() => onCancel()}
      onConfirm={() => handleSubmit()}
    />
  );
}

EditProfile.propTypes = {
  id: PropTypes.string,
  formIndex: PropTypes.number,
  defaultValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}
