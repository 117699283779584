import PropTypes from 'prop-types'
import { useState } from 'react';
import {
  Stack,
  IconButton,
  InputAdornment
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PasswordChecklist from 'react-password-checklist'

import TextField from 'components/TextField';
import Iconify from 'components/Iconify';

export default function AcceptInviteFormStepAccess({ values, handleChange, touched, errors }) {
  const { t } = useTranslation('common');

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Stack spacing={3}>
      <TextField
        name="email"
        label={t('auth.acceptInvite.form.email')}
        value={values.email}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />

      <TextField
        name="phone"
        mask="(99) 99999-9999"
        label={t('auth.acceptInvite.form.phone')}
        value={values.phone}
        onChange={handleChange}
        error={touched.phone && Boolean(errors.phone)}
        helperText={touched.phone && errors.phone}
      />

      <TextField
        name="password"
        label={t('auth.acceptInvite.form.password')}
        type={showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={touched.password && Boolean(errors.password)}
      />

      <div className="password-checklist">
        <PasswordChecklist
          rules={['minLength', 'specialChar', 'number', 'capital']}
          minLength={8}
          value={values.password}
          messages={{
            minLength: 'A senha deve conter pelo menos 8 caracteres.',
            specialChar: 'Pelo menos um caracter especial.',
            number: 'Pelo menos um número.',
            capital: 'Pelo menos uma letra maiúscula',
          }}
        />
      </div>
    </Stack>
  );
}

AcceptInviteFormStepAccess.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
}
